
.hero {
  min-height: 100svh;
  padding: 150px 30px 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-height: 800px) and (min-width: 768px) {
    padding-top: 130px;
    padding-bottom: 40px; }

  @include md {
    padding: 20px 20px 28px; }
  @include xs {
    padding: 20px 15px 20px; }

  &:not(.hero_min):before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../img/hero-overlay.svg) center top repeat;
    background-color: rgba(0, 0, 0, .3);
    z-index: 1;

    @include sm {
      background: url(../img/overlay_small.svg);
      background-color: rgba(0, 0, 0, .3); } }

  &.hero_teams {
    position: relative;
    padding-top: 33%;
    padding-bottom: 80px;
    min-height: 0;
    background: url(../img/hero.png) center top no-repeat;
    background-size: cover;
    margin-bottom: -20%;
    z-index: -1;

    &:before {
      display: none; }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, $mainBg 90%); } }

  &__inner {
    position: relative;
    max-width: 1080px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 2; }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px; }

  &__logo {
    margin: 0;
    max-width: 130px;
    display: none;
    margin-bottom: 40px;
    @include md {
      display: block; }
    img {
      display: block;
      max-width: 100%; } }

  &__date {
    font-size: 24px;
    font-weight: 500;
    @include md {
      font-size: clamp(16px, 2.2vw, 20px); } }

  &__content {
    display: flex;
    margin: auto 0;
    flex-wrap: wrap;
    gap: 40px 30px;

    @include sm {
      position: relative;
      margin: auto 0;
      flex-direction: column;
      top: 0;
      gap: 20px; } }

  &__left {
    text-align: left;
    max-width: 640px;
    flex: 1;
    @include sm {
      max-width: none;
      margin-right: 0;
      flex: none; }
    @include xs {
      text-align: left; } }

  &__right {
    flex: 0 1 auto;
    max-width: 514px;
    align-self: flex-end;
    @include md {
      max-width: 400px; }
    @include sm {
      align-self: flex-start;
      margin-top: 10px; } }

  &__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px 30px;
    margin-top: 50px;
    @include sm {
      margin-top: 30px;
      flex-direction: column; }

    @include xs {
      justify-content: center; }

    .socials {
      margin: 26px 20px 0 0;
      @include xs {
        margin: 0; } } }
  &__head {
    width: 100%; }
  &__title {
    @include black;
    font-size: clamp(38px, 6.8vw, 72px);
    line-height: 1.04;
    display: block;
    padding: 0;
    z-index: 2;
    max-width: 640px;
    @media screen and (max-height: 800px) and (min-width: 1025px) {
      max-width: 800px; }
    @include sm {
      max-width: none; }

    & > div {
      display: inline;
      position: relative;
      max-width: max-content; } }
  &__sticky {
    position: absolute;
    bottom: 2%;
    right: -15%;
    white-space: nowrap;
    text-transform: uppercase;
    color: $black;
    padding: 0.5em 1em;
    border-radius: 100px;
    background: #FEF502;
    font-size: clamp(10px, 1.2vw, 16px);
    transform: rotate(-8.463deg);
    font-weight: 700;
    @include sm {
      bottom: -10%;
      right: -20%;
      transform: rotate(-4.463deg); } }

  &__subtitle {
    @include medium;
    margin: 44px 0 60px;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.26;
    letter-spacing: -0.005em;
    display: block;
    z-index: 2;
    text-align: center;

    @include md {
      font-size: 32px; }

    @include sm {
      font-size: 22px;
      margin: 32px 0 40px; }

    @include xs {
      margin: 26px 0 32px; } }

  &__descr {
    @include regular;
    margin: 30px 0 0;
    font-size: clamp(14px, 2vw, 16px);
    line-height: 1.6;
    @include sm {
      margin: 20px 0 0;
      line-height: 1.4; } }

  &__btn {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 514px;
    gap: 12px 20px;
    margin-left: auto;

    @include tablet {
      gap: 12px 24px; }
    @include md {
      flex: 0 0 400px;
      gap: 12px; }
    @include sm {
      flex: none;
      width: 100%; }

    .btn {
      flex: 1;
      min-height: 56px;
      font-size: 16px;
      max-width: calc(50% - 10px);
      @include md {
        max-width: calc(50% - 6px); }
      @include sm {
        max-width: none;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 120px;
        font-size: 14px; }
      @include xs {
        font-size: 12px;
        min-height: 44px; } } }

  &__switch {
    display: flex;
    width: 100%;
    max-width: 400px;
    gap: 16px;
    border-radius: 100px;
    border: 1px solid $themeColor2;
    @include md {
      max-width: none;
      width: max-content; }
    @include sm {
      gap: 10px; }
    @include xs {
      max-width: 280px; }
    &-handler {
      display: none; }
    &-label {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 12px;
      letter-spacing: -0.16px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      flex: auto;
      line-height: 1.2;
      padding-right: 15px;
      @include sm {
        font-size: 14px;
        height: 40px;
        gap: 8px; }
      @include xs {
        height: 31px;
        font-size: 12px; }

      a {
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: underline; } } }
    &:not(.hero__switch--reverse) {
      .hero__switch-label {
        &:first-child {
          background: $themeColor2;
          color: $black;
          white-space: nowrap;
          padding: 0 25px;
          border-radius: inherit;
          @include md {
            padding: 0 20px; }
          @include xs {
            padding: 0 15px; }
          &:before {
            content: url('../img/country.svg');
            vertical-align: middle;
            display: block;
            font-size: 0;
            margin-left: -9px;
            @include xs {
              margin-left: -12px; } } } } }

    &.hero__switch--reverse {
      .hero__switch-label {
        padding-right: 0;
        padding-left: 15px;
        &:last-child {
          background: $themeColor2;
          color: $black;
          white-space: nowrap;
          padding: 0 25px;
          border-radius: inherit;
          @include md {
            padding: 0 20px; }
          @include xs {
            padding: 0 15px; }
          &:before {
            content: url('../img/country.svg');
            vertical-align: middle;
            display: block;
            font-size: 0;
            margin-left: -9px;

            @include xs {
              margin-left: -12px; } } } } } }

  &_min, &_sm {
    min-height: auto;
    padding: 180px 0 100px;
    background-position: center;
    background-size: cover;

    &:after {
      display: none; }
    @include md {
      padding: 94px 20px; }
    @include xs {
      padding: 60px 20px; }
    .hero {
      &__logo {
        max-width: 230px;

        @include md {
          margin: 0 auto 40px; }
        @include xs {
          max-width: 150px;
          margin-bottom: 20px; } }
      &__title {
        font-size: 72px;
        max-width: 1280px;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        line-height: 1.1;
        @include tablet {
          font-size: 60px; }
        @include md {
          font-size: 44px; }
        @include sm {
          font-size: 32px; } } } }
  &_sm {
    padding: 50px 0;
    .hero__logo {
      margin-top: 0; }
    @include md {
      padding: 50px 0; } }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center; }

    @media (prefers-reduced-motion) {
      display: none; } }

  &_live {
    align-items: flex-start;
    height: 130px;
    min-height: 0;
    padding: 20px 0;

    @include md {
      height: 120px; }

    @include xs {
      height: auto; }

    .container {
      position: relative;
      z-index: 2;
      pointer-events: auto;
      width: 100%;
      height: 100%; }

    .hero {
      &__bg {

        position: absolute;
        left: 0;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        background: url(../img/coc.jpg) center top no-repeat;
        background-size: cover; }

      &__inner {
        position: relative;
        max-width: none;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;

        @include md {
          margin: 0; }

        @include xs {
          flex-direction: column-reverse;
          align-items: center; } }

      &__date {
        font-size: 18px;
        margin: auto 0;

        @include md {
          font-size: 14px; }

        @include xs {
          font-size: 12px;
          margin: 15px 0 0; } }

      &__logo {
        width: 200px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        display: block;

        @include md {
          width: 150px;
          margin: 0px auto 0; }

        @include xs {
          position: relative;
          left: 0;
          top: 0;
          transform: translate(0, 0);
          width: 130px;
          margin: 0px auto 0; } }

      &__partner {
        position: relative;
        transform: none;
        height: auto;
        margin: auto 0 auto auto;
        top: 0;
        right: 0;

        @include tablet {
          font-size: 10px; }

        @include md {
          margin: auto 0 auto;

          img {
            height: 45px; } }

        @include xs {
          display: none; }

        img {
          height: 65px; } }

      &__partners {
        @include md {
          margin: 0; } }

      &__partner-text {
        @include sm {
          display: none; } } } } }

.intro {
  z-index: 1;
  position: relative; }

@media screen and (prefers-reduced-motion: reduce) {
  .hero__video {
    display: none !important; } }
