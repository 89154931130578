 :root {
  --banner-font: Sora,sans-serif;
  --banner-accent-font: Sora,sans-serif;
  --banner-btn-size: 12px;
  --banner-radius: 16px;

  --accentColor: #fef502;

  --price-increase-bg: #212121;
  --price-increase-color: #fff;

  --checkout-section-bg: #353535;
  --checkout-section-color: #fff;
  --checkout-card-border: 2px solid #353535;
  --checkout-card-bg: #212121;
  --checkout-card-radius: 16px;
  --checkout-card-text-color: #fff;
  --checkout-quantity-hover-color: #000;
  --checkout-quantity-border: 1px solid #555;
  --checkout-quantity-button-bg: #fef502;
  --checkout-quantity-button-bg-hover: #fff;
  --checkout-quantity-button-border: 2px solid #fef502;
  --checkout-quantity-button-border-hover: 2px solid #fff;
  --checkout-quantity-button-icon-color: #000;
  --checkout-quantity-button-icon-color-hover: #000;
  --checkout-form-ff: GothamPro,Arial,Helvetica,sans-serif;
  --checkout-form-border: 1px solid #555;
  --checkout-form-color: #fff;
  --checkout-price-ff: GothamPro,Arial,Helvetica,sans-serif;
  --checkout-card-title-weight: 700;
  --checkout-button-weight: 700;
  --checkout-button-border: 2px solid #fef502;
  --checkout-button-border-hover: 2px solid #fff;

  --cehckout-badge-bg: #444;
  --cehckout-badge-color: #fff;

  --price-section-bg: #353535;
  --price-color: #fff;

  --price-item-bg: #212121;
  --price-item-border: 2px solid #363636;
  --price-item-radius: 16px;
  --price-item-title-color: #fff;
  --price-item-title-weight: 700;

  --price-slider-btn-size: 54px;
  --price-slider-btn-border-color: #fef502;
  --price-slider-btn-radius: 50%;
  --price-slider-btn-color: #212121;
  --price-slider-btn-bg: #fef502;
  --price-slider-btn-bg-hover: #fff;

  --price-btn-color: #fff;
  --price-btn-border: 1px solid #7B7B7B;
  --price-btn-border-color-hover: #999;

  --ticketsFormBg: #212121;
  --ticketsFormRadius: 16px;
  --ticketsFormInputBorder: 1px solid #7B7B7B;
  --ticketsFormInputRadius: 100px;

  --workshop-item-ff: 'Manrope';
  --workshop-item-type-radius: 100px;
  --workshop-item-color: #fff;
  --workshop-item-color-hover: #fff;
  --workshop-item-border: #5A5A5A;
  --workshop-item-bg: #5A5A5A;
  --workshop-item-type-bg: #fff; }

/*----- COLORS -----*/

$mainBg: #212121;
$mainBg2: #353535;
$themeColor: #fef502;
$themeColor2: #fef502;
$themeColorD: #fef502;
$white: #ffffff;
$grayD: #8D8D8D;
$gray: #424242;
$grayL: #eaeaec;
$black: #353535;

// end COLORS

/*----- SCHEDULE -----*/
$posTop: 0;
$scheduleColorGray: #c5c5c5;
$scheduleColorGrayL: #eaeaec;
$scheduleOutline: #8D8D8D;
// end SCHEDULE

/*----- SWITCH -----*/
$switchColor: $black;
$switchHandler: $black;

// end SWITCH
